$line-height-base: 1.6;

// Sizes
$font-size-x-small: 0.75rem; //12px
$font-size-small: 0.875rem; //14px
$font-size-regular: 1rem; //16px
$font-size-medium: 1.125rem; //18px
$font-size-large: 1.25rem; //20px
$font-size-x-large: 1.5rem; //24px
$font-size-xx-large: 2.5rem; //40px
$font-size-xxx-large: 4rem; //64px
$font-size-xs-large: 2.25rem; //36px
$font-size-xl-large: 3rem; //48px
$font-size-1x-large: 4rem; //64px

// Weight
$font-weight-light: 100;
$font-weight-regular: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;

// Families
$poppins: "Poppins", sans-serif;
$open-sans: "Open Sans", sans-serif;
$consolas: "Inconsolata", monospace;

// Preset
$poppins-x-large-light: $font-weight-light $font-size-x-large $poppins;
$poppins-regular-small: $font-weight-regular $font-size-small $poppins;
$poppins-regular: $font-weight-regular $font-size-regular $poppins;
$poppins-medium: $font-weight-regular $font-size-medium $poppins;
$poppins-large: $font-weight-regular $font-size-large $poppins;
$poppins-x-large: $font-weight-regular $font-size-x-large $poppins;
$poppins-xx-large: $font-weight-regular $font-size-xx-large $poppins;
$poppins-xs-large: $font-weight-light $font-size-xx-large $poppins;

$poppins-bold-small: $font-weight-bold $font-size-small $poppins;
$poppins-bold-regular: $font-weight-bold $font-size-regular $poppins;
$poppins-bold-medium: $font-weight-bold $font-size-medium $poppins;
$poppins-bold-large: $font-weight-bold $font-size-large $poppins;
$poppins-bold-x-large: $font-weight-bold $font-size-x-large $poppins;
$poppins-bold-xx-large: $font-weight-bold $font-size-xx-large $poppins;
$poppins-bold-xx-large: $font-weight-bold $font-size-xx-large $poppins;
$poppins-bold-xxx-large: $font-weight-bold $font-size-xxx-large $poppins;
$poppins-bold-xl-large: $font-weight-bold $font-size-xl-large $poppins;

$open-sans-x-small: $font-weight-regular $font-size-x-small $open-sans;
$open-sans-small: $font-weight-regular $font-size-small $open-sans;
$open-sans-light-regular: $font-weight-light $font-size-regular $open-sans;
$open-sans-regular: $font-weight-regular $font-size-regular $open-sans;
$open-sans-medium: $font-weight-regular $font-size-medium $open-sans;
$open-sans-x-large: $font-weight-regular $font-size-x-large $open-sans;

$open-sans-bold-x-small: $font-weight-bold $font-size-x-small $open-sans;
$open-sans-bold-small: $font-weight-bold $font-size-small $open-sans;
$open-sans-bold-regular: $font-weight-bold $font-size-regular $open-sans;
$open-sans-bold-medium: $font-weight-bold $font-size-medium $open-sans;
$open-sans-bold-large: $font-weight-bold $font-size-large $open-sans;
$open-sans-bold-x-large: $font-weight-bold $font-size-x-large $open-sans;
$open-sans-bold-xs-large: $font-weight-bold $font-size-xs-large $open-sans;

////////////
// COLORS //
////////////

// Buttons & Text
$primary-blue: #2b61c4;
$blue-black: #013557;
$shadow-blue: #1e4489;
$shadow-medium-blue: #d8e1e9;
$shades-blue: #47bcff;
$lighter-blue: #eaeff3;
$light-blue: #b1d8ff;
$medium-blue: #3792eb;
$medium-purple: #862195;
$purple-blue: #2118a7;
$alice-blue: #e9f9ff;
$primary-red: #db0000;
$primary-yellow: #f2a422;
$shadow-yellow: #b3a819;
$light-yellow: #fff097;
$light-grayish-yellow: #d8d38b;
$primary-green: #508d3d;
$light-black: #808080;
$dark-pink: #ff00f7;
$darker-blue: #021556;
$espresso: #5b1919;
$light-myeg: #ffcfcf;
$scheme-invert: black;
$scheme-main: white;
$dark-purple: #6158bd;
$dark-blue: #3b77a7;
$link-green: #d0ffb1;
$toast-success: #205100;
$secondary-green: #008e2f;

// Background
$bg-grey: #f9f9f9;
$bg-blue: #497aa1;
$bg-red: #ff0000;
$bg-light-red: #fbdddd;
$bg-dark-maroon: #7d0303;
$light-yellow: #fff9a2;
$deep-yellow: #ffd500;
$golden-yellow: #ffe200;
$bg-strong-red: #a60c2e;
$palatinate-blue: #233fe3;
$blue-ribbon: #006dfa;
$dark-red: #f14668;
$light-grey: #fbf9f9;
$bg-dark-blue: #0f0b7a;
$bg-darker-blue: #0d426d;
$bg-light-green: #c7fed2;
$bg-light-yellow: #fff7af;
$bg-dark-grey: #3b3b3b;
$bg-light-blue: #eef5fc;
$bg-lighter-green: #e9ffdb;

// Monochrome
$white: #ffffff;
$white-grey: #fafafa;
$white-grey-black: #b2b2b2;
$light-white: #f8f9fa;
$white-smoke: #f2f2f2;
$grey: #8c8c8c;
$grey-1: #efefef;
$grey-2: #dddddd;
$grey-3: #cccccc;
$grey-4: #999999;
$grey-5: #666666;
$grey-6: #333333;
$grey-7: #dfdfdf;
$grey-8: #8b8b8b;
$grey-9: #748090;
$grey-10: #00000033;
$grey-11: #5b5b5b;
$grey-12: #e9e9e9;
$grey-13: #707070;
$grey-14: #888888;
$grey-15: #cbcbcb;
$black: #000000;
$shades-tints: #151515;
$dark-grey: #18293e;

////////////
// OTHERS //
////////////

$global-radius: 8px;

// MAIN SLIDER STACK (z-index)
$red-glow-stack: 1;
$slider-img-stack: 2;
$diagonal-stack: 3;
$slider-text-stack: 4;
$slider-button-stack: 5;
$home-content-stack: 6;
$footer-stack: 6;

// NAVBAR
$navbar-desktop-height: 80px;
$navbar-mobile-height: 64px;

//Menubar
$menubar-expend-width: 241px;
$menubar-collapse-width: 90px;

// Modal
$modal-header-height: 88px;
$modal-header-height-mobile: 64px;

$header-height: 88px;
$header-height-mobile: 64px;

// Shadow
$box-shadow: #00000029;

// Footer
$footer-height: 243px;