// Applied when use [myegInput] directive.
.myeg-input {
    background: $white;
    border: 1px solid $primary-blue;
    border-radius: 4px;
    height: 48px;
    padding: 13px 10px;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px $box-shadow;
    &:hover,
    &:focus,
    &:active {
      border-color: $primary-blue;
      outline: none;
    }
  
    &:focus,
    &:active {
      border-width: 2px;
      // box-shadow: 0 3px 6px rgba($color: #000000, $alpha: 0.3);
    }
  
    &:disabled {
      background-color: $grey-1;
      border: 1px solid $grey-3;
      color: $grey-4;
    }
  
    // Error
    &-error {
      border-color: $primary-red;
  
      &:hover,
      &:focus,
      &:active {
        border-color: $primary-red;
      }
    }
  
    &.is-fullwidth {
      width: 100%;
    }
  
    &[type="password"] {
      font-size: 44px;
      padding: 0.5rem;
      padding-right: 40px;
      line-height: 44px;
      &::placeholder {
        transform: translateY(-12px);
      }
    }
    &::placeholder {
      font: $open-sans-regular;
      color: $grey-4;
      font-style: italic;
    }
    &.select {
      appearance: none;
      background-image: url("~src/assets/icons/icon-select-dropdown.svg");
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 1em;
    }
  }
  
  input.vims-input {
    border: none;
    box-shadow: none;
    background-color: none;
    background: transparent linear-gradient(91deg, #005C99 0%, #005C99 47%, #4197D1 100%) 0% 0%;
    background-repeat: no-repeat;
    background-position: calc(100% + 3px) bottom;
    background-size: 100% 3px;
    font: $open-sans-bold-regular;
    height: 48px;
    padding: 13px 10px;
    box-sizing: border-box;
    &:hover,
    &:focus,
    &:active {
      border-color: rgba(255, 255, 255, 0.1);
      outline: none;
      box-shadow: none;
    }
  
    &:focus,
    &:active {
      border-width: 3px;
      box-shadow: none;
    }
  
    &:disabled {
      color: $grey-4;
      box-shadow: none;
    }
  
    // Error
    &-error {
        background: transparent linear-gradient(91deg, $primary-red 0%, $primary-red 47%, $primary-red 100%) 0% 0%;
  
  
      &:hover,
      &:focus,
      &:active {
          background: transparent linear-gradient(91deg, $primary-red 0%, $primary-red 47%, $primary-red 100%) 0% 0%;
  
      }
    }
  
    &.is-fullwidth {
      width: 100%;
    }
  
    &[type="password"] {
      font-size: 44px;
      padding: 0.5rem;
      padding-right: 40px;
      line-height: 44px;
      &::placeholder {
        transform: translateY(-12px);
      }
    }
    &::placeholder {
      font: $open-sans-regular;
      color: $grey-4;
      font-style: italic;
    }
    @media (max-width: 992px) {
      background: none;
      border-bottom: 3px solid rgba(255, 255, 255, 0.1);
      color: $white;
    }
  }
  
  input::placeholder {
    font: 400 1rem "Open Sans", sans-serif;
    font-style: italic;
    color: #b2b2b2;
  }
  
  .myeg-input-suffix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
  }